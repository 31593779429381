export const RuDictionary = {
  start: 'Начать',
  competitions: 'Соревнования',
  profile: 'Профиль',
  rating: 'Рейтинг',
  signIn: 'Войти',
  signOut: 'Выйти',
  signUp: 'Регистрация',
  active: 'Активные',
  myCompetitions: 'Мои соревнования',
  archive: 'Прошедшие',
  registrationClosed: 'Регистрация закрыта',
  startFee: 'Стартовый взнос',
  participantsEnrolled: 'участников зарегистрировано',
  members: 'Члены клуба',
  competitionOver: 'Это соревнование окончено',
  results: 'Результаты',
  man: 'Мужчина',
  woman: 'Женщина',
  age: 'Возраст',
  years: 'лет',
  weight: 'Вес',
  gender: 'Пол',
  description: 'Описание',
  kg: 'кг',
  competitionRequirements: 'Требования соревнования',
  points: 'Очки',
  competitionSchedule: 'Расписание соревнования',
  judge: 'Судья',
  waiting: 'Ожидает',
  finished: 'Окончен',
  inProgress: 'Бой идет',
  editProfile: 'Редактировать профиль',
  email: 'Электронная почта',
  name: 'Имя',
  firstName: 'Имя',
  lastName: 'Фамилия',
  birthday: 'День рождения',
  fightClub: 'Бойцовский клуб',
  country: 'Страна',
  city: 'Город',
  username: 'Имя пользователя',
  save: 'Сохранить',
  changePassword: 'Изменить пароль',
  forgotPassword: 'Забыл пароль',
  repeatPassword: 'Повторите пароль',
  enter: 'Введите',
  haveAccount: 'Уже есть аккаунт',
  sendLink: 'Отправить ссылку на почту',
  emailConfirmation: 'Подтверждение почты',
  password: 'Пароль',
  confirmPassword: 'Подтвердите пароль',
  searchUsers: 'Поиск пользователей',
  show: 'Показать',
  showMore: 'Показать больше',
  cleanUp: 'Очистить',
  min: 'От',
  max: 'До',
  filter: 'Фильтр',
  location: 'Расположение',
  place: 'Место',
  toCompetition: 'К соревнованию',
  downloadOlympicGrid: 'Скачать олимпийскую сетку',
  takeBreak: 'Сделать перерыв',
  competitionOn: 'Соревнование идет',
  winner: 'Победитель',
  participants: 'Участники',
  checkPayment: 'Эти участники просят проверить оплату',
  participantExcluded: 'Участники исключены из-за 3 неправильных запросов на оплату',
  participantAdmitted: 'Участники, допущенные к участию в соревновании',
  paid: 'Оплачено',
  notPaid: 'Не оплачено',
  assignJudges: 'Назначить судей',
  judges: 'Судьи',
  continue: 'Продолжить',
  createGroups: 'Создание групп',
  sex: 'Пол',
  outgroup: 'Вне групп',
  addGroup: 'Добавить группу',
  groups: 'Группы',
  confirmGroups: 'Подтвердить группы',
  confirmPairs: 'Подтвердить пары',
  noGroups: 'Группы не созданы',
  previousStep: 'Предыдущий шаг',
  createPairs: 'Создавайте пары',
  orderOlympicGrid: 'Порядок для олимпийской сетки',
  assignOrders: 'Назначайте порядок парам в группах',
  connectJudges: 'Присоедините судей к парам',
  done: 'Готово',
  validationErrorsAll: 'Проверьте все поля на наличие ошибок',
  checkEmailConfirm: 'Пожалуйста, проверьте свою электронную почту для подтверждения',
  passwordChanged: 'Ваш пароль был изменен',
  profileUpdated: 'Профиль обновлен',
  linkActive: 'Ссылка для смены пароля отправлена на вашу почту. Ссылка активна 5 минут',
  transferAccount: 'Перевод из России на банковский счет',
  downloadInfo: 'Скачать дополнительную информацию',
  transferred: 'Деньги отправил',
  warningCheckPayment: 'Вы можете запросить проверку 3 раза',
  haveQuestions: 'Если есть вопросы, пишите',
  awaitConfirmation: 'Ваш запрос на проверку оплаты принят, дождитесь подтверждения',
  participate: 'Участвовать',
  registrationClosed: 'Регистрация закрыта',
  registrationEndsIn: 'Регистрация заканчивается через',
  days: 'дней',
  day: 'день',
  hours: 'часов',
  hour: 'час',
  minutes: 'минут',
  minute: 'минуту',
  seconds: 'секунд',
  second: 'секунду',
  checkParticipants: 'Посмотреть участников',
  lessMinValue: 'Не может быть меньше нижнего значения',
  maxValue: 'макс значение',
  minValue: 'мин значение',
  createNewPairs: 'Создать новые пары',
  linkToZoom: 'Ссылка на Zoom',
  yourPlace: 'Ваше место',
  timeBeforeStart: 'Примерное время до начала',
  youIn: 'Бой начат',
  youDisqualified: 'Вы дисквалифицированы',
  youAre: 'Вы',
  participant: 'участник',
  informationWillPublished: 'Дополнительная информация будет опубликована позже',
  goToPage: 'Подробности на странице соревнования',


  latinLettersAllowed: 'Разрешены только латинские буквы',
  isNumber: 'Должен быть числом',
  maxWeight: 'Максимальное значение 199',
  isDateValid: 'Введите правильную дату в формате ДД.ММ.ГГГГ. Например 10.07.1999',
  isFuture: 'День рождения не может быть в будущем',
  maxAge: 'Максимальный возраст 99 лет',
  incorrect: 'Неправильный',
  required: 'Обязательное поле',
  isPassword: 'Пароль должен содержать цифру, букву и специальный символ',
  minLength: 'Минимум 8 символов',
  isPasswordEqual: 'Пароли не совпадают',
}
