export const EnDictionary = {
  start: 'Start',
  competitions: 'Competitions',
  profile: 'Profile',
  rating: 'Rating',
  signIn: 'Sign In',
  signOut: 'Sign Out',
  signUp: 'Sign Up',
  active: 'Active',
  myCompetitions: 'My competitions',
  archive: 'Archive',
  registrationClosed: 'Registration closed',
  startFee: 'Start fee',
  participantsEnrolled: 'participants enrolled',
  members: 'Members',
  competitionOver: 'This competition is over',
  results: 'Results',
  man: 'Man',
  woman: 'Woman',
  age: 'Age',
  years: 'age',
  weight: 'Weight',
  gender: 'Gender',
  description: 'Description',
  kg: 'kg',
  competitionRequirements: 'Competition requirements',
  points: 'Points',
  competitionSchedule: 'Competition schedule',
  judge: 'Judge',
  waiting: 'waiting',
  finished: 'finished',
  inProgress: 'in progress',
  editProfile: 'Edit Profile',
  email: 'Email',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  birthday: 'Birthday',
  fightClub: 'Fight club',
  country: 'Country',
  city: 'City',
  username: 'Username',
  save: 'Save',
  changePassword: 'Change Password',
  forgotPassword: 'Forgot Password',
  repeatPassword: 'Repeat Password',
  enter: 'Enter',
  haveAccount: 'Already have an account',
  sendLink: 'Send link to Email',
  emailConfirmation: 'Email Confirmation',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  searchUsers: 'Search users',
  show: 'Show',
  showMore: 'Show more',
  cleanUp: 'Clean Up',
  min: 'Min',
  max: 'Max',
  filter: 'Filter',
  location: 'Location',
  place: 'Place',
  toCompetition: 'To competition',
  downloadOlympicGrid: 'Download olympic grid',
  takeBreak: 'Take a break',
  competitionOn: 'The competition is on',
  winner: 'Winner',
  participants: 'Participants',
  checkPayment: 'These participants request to check payment',
  participantExcluded: 'Participants excluded due to 3 incorrect payment request',
  participantAdmitted: 'Participants admitted to the competition',
  paid: 'Paid',
  notPaid: 'Not paid',
  assignJudges: 'Assign judges',
  judges: 'Judges',
  continue: 'Continue',
  createGroups: 'Create groups',
  sex: 'Sex',
  outgroup: 'Outgroup',
  addGroup: 'Add group',
  groups: 'Groups',
  confirmGroups: 'Confirm the groups',
  confirmPairs: 'Confirm the pairs',
  noGroups: 'No groups created',
  previousStep: 'Previous step',
  createPairs: 'Create pairs',
  orderOlympicGrid: 'Order for olympic grid',
  assignOrders: 'Assign orders to groups',
  connectJudges: 'Connect judges to pairs',
  done: 'Done',
  validationErrorsAll: 'Check all fields for validation errors',
  checkEmailConfirm: 'Please, check you email to confirm',
  passwordChanged: 'Your password has been changed',
  profileUpdated: 'Profile updated',
  linkActive: 'Link to change password was sent to your email. Link active 5 minutes',
  transferAccount: 'Transfer from Russia on bank account',
  downloadInfo: 'Download additional info',
  transferred: 'Transferred',
  warningCheckPayment: 'You can request check payment only 3 times',
  haveQuestions: 'If you have any questions, please contact',
  awaitConfirmation: 'Your request to check payment has been accepted, please await confirmation',
  participate: 'Participate',
  registrationClosed: 'Registration Closed',
  registrationEndsIn: 'Registration ends in',
  days: 'days',
  day: 'day',
  hours: 'hours',
  hour: 'hour',
  minutes: 'minutes',
  minute: 'minute',
  seconds: 'seconds',
  second: 'second',
  checkParticipants: 'Check out participants',
  lessMinValue: 'Cannot be less than min value',
  maxValue: 'max value',
  minValue: 'min value',
  createNewPairs: 'Create new pairs',
  linkToZoom: 'Link to Zoom',
  yourPlace: 'Your place',
  timeBeforeStart: 'Approximate time before start',
  youIn: 'You are in',
  youDisqualified: 'You are disqualified',
  youAre: 'You are',
  participant: 'participant',
  informationWillPublished: 'Additional information will be published later',
  goToPage: 'Go to the competition page for details',

  latinLettersAllowed: 'Only latin letters are allowed',
  isNumber: 'Must be a number',
  maxWeight: '199 max value',
  isDateValid: 'Enter correct date in DD.MM.YYYY. For example 10.07.1999',
  isFuture: "Birthday can't be future",
  maxAge: '99 max age',
  incorrect: 'Incorrect',
  required: 'Required',
  isPassword: 'Password must contain number, letter and special character',
  minLength: 'Minimum 8 characters',
  isPasswordEqual: 'Passwords do not match'
}
